import * as React from 'react'
import { Link } from 'gatsby'
import HeaderMenu from '../components/HeaderMenu/HeaderMenu'
import { withLayout, LayoutProps, menuItems } from '../components/Layout'
import {
  Segment,
  Container,
  Grid,
  Header,
  Icon,
  Button
} from 'semantic-ui-react'
import FormModalButton from '../components/Form/Form'
import { isMobile } from 'react-device-detect'
import { CustomerReviewCards } from '../components/CustomerReviewCards/CustomerReviewCards'
import { Instagram } from '../components/Instagram/Instagram'
import { determineHeightFromDevice } from '../utils/helpers'
import Bounce from 'react-reveal/Bounce'
import Zoom from 'react-reveal/Zoom'
import { Carousel } from '../components/Carousel/Carousel'
import ImageCarousel from '../components/Carousel/NewCarousel'
// Icons
const concreteMixer = require('../../assets/transport.svg')
const house = require('../../assets/buildings.svg')
const mobilePhone = require('../../assets/mobile-phone.svg')
const job1Img = require('../../assets/job1.jpg')
const job2Img = require('../../assets/job2.jpg')
const job3Img = require('../../assets/job3.jpg')
const job4Img = require('../../assets/job4.jpg')
const job5Img = require('../../assets/job5.jpg')
const job6Img = require('../../assets/job6.jpg')
const job7Img = require('../../assets/job7.jpg')
const job8Img = require('../../assets/job8.jpg')
const job9Img = require('../../assets/job9.jpg')
const job10Img = require('../../assets/job10.jpg')
const job11Img = require('../../assets/job11.jpg')
const job12Img = require('../../assets/job12.jpg')
const job13Img = require('../../assets/job13.jpg')
const job14Img = require('../../assets/job14.jpg')
const job15Img = require('../../assets/job15.jpg')
const job16Img = require('../../assets/job16.jpg')
const job17Img = require('../../assets/job17.jpg')
const job18Img = require('../../assets/job18.jpg')
const job19Img = require('../../assets/job19.jpg')
const job20Img = require('../../assets/job20.jpg')
const job21Img = require('../../assets/job21.jpg')
const job22Img = require('../../assets/job22.jpg')
const job23Img = require('../../assets/job23.jpg')
const job24Img = require('../../assets/job24.jpg')
const job25Img = require('../../assets/job25.jpg')
const job26Img = require('../../assets/job26.jpg')
const job27Img = require('../../assets/job27.jpg')
const job28Img = require('../../assets/job28.jpg')
const job29Img = require('../../assets/job29.jpg')
const job30Img = require('../../assets/job30.jpg')
const job31Img = require('../../assets/job31.jpg')
const job32Img = require('../../assets/job32.jpg')
const job33Img = require('../../assets/job33.jpg')
const job34Img = require('../../assets/job34.jpg')
const job35Img = require('../../assets/job35.jpg')
const job36Img = require('../../assets/job36.jpg')
const job37Img = require('../../assets/job37.jpg')
const job38Img = require('../../assets/job38.jpg')
const job39Img = require('../../assets/job39.jpg')
const job40Img = require('../../assets/job40.jpg')
const job41Img = require('../../assets/job41.jpg')
const job42Img = require('../../assets/job42.jpg')
const job43Img = require('../../assets/job43.jpg')
const job44Img = require('../../assets/job44.jpg')
const job45Img = require('../../assets/job45.jpg')
const job46Img = require('../../assets/job46.jpg')
const job47Img = require('../../assets/job47.jpg')
const job48Img = require('../../assets/job48.jpg')
const job49Img = require('../../assets/job49.jpg')
const job50Img = require('../../assets/job50.jpg')
const job51Img = require('../../assets/job51.jpg')
const job52Img = require('../../assets/job52.jpg')
const job53Img = require('../../assets/job53.jpg')
const job54Img = require('../../assets/job54.jpg')
const job55Img = require('../../assets/job55.jpg')
const job56Img = require('../../assets/job56.jpg')
const job57Img = require('../../assets/job57.jpg')
const job58Img = require('../../assets/job58.jpg')
const job59Img = require('../../assets/job59.jpg')
const job60Img = require('../../assets/job60.jpg')
const job61Img = require('../../assets/job61.jpg')
const job62Img = require('../../assets/job62.jpg')
const job63Img = require('../../assets/job63.jpg')
const job64Img = require('../../assets/job64.jpg')
const job65Img = require('../../assets/job65.jpg')
const job66Img = require('../../assets/job66.jpg')
const job67Img = require('../../assets/job67.jpg')
const job68Img = require('../../assets/job68.jpg')
const job69Img = require('../../assets/job69.jpg')
const job70Img = require('../../assets/job70.jpg')

const bannerStyles = {
  backgroundColor: 'white',
  paddingBottom: '50px',
  paddingTop: '5px',
  background: 'rgba(230, 230, 230, .7)'
}

const IndexPage = (props: LayoutProps) => {
  return (
    <div>
      {/* Master head */}
      <Segment
        vertical
        textAlign="center"
        className="masthead"
        style={{
          backgroundImage: `url(https://images.unsplash.com/photo-1560780552-ba54683cb263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2550&q=80)`,
          backgroundColor: 'white',
          height: determineHeightFromDevice('100vh', '60vh')
        }}
      >
        <HeaderMenu
          Link={Link}
          pathname={props.location.pathname}
          items={menuItems}
        />
        <Container style={bannerStyles}>
          <Header
            style={{ marginTop: 50, fontFamily: 'Oswald', color: '#141a26' }}
            as="h1"
          >
            Atlanta's Trusted Concrete Provider
          </Header>
          <Header as="h2" style={{ fontFamily: 'Oswald', color: '#141a26' }}>
            Driveways | Patios | Sidewalks
          </Header>
          <Header as="h2" style={{ fontFamily: 'Oswald', color: '#141a26' }}>
            Residential | Commercial
          </Header>
          <Header
            as="h2"
            style={{
              fontFamily: 'Oswald',
              color: '#141a26',
              textAlign: '-webkit-center'
            }}
          >
            Call Today For A Free Estimate
            <Zoom>
              <Button
                circular
                style={{
                  display: 'block',
                  background: '#FFF',
                  boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
                  marginTop: '10px'
                }}
              >
                <a href="tel:678-724-7984">
                  <Icon
                    color={'green'}
                    name={'phone'}
                    size={isMobile === true ? 'huge' : 'big'}
                  />
                </a>
              </Button>
            </Zoom>
          </Header>
          {/* Button to open free estimate modal */}
        </Container>
      </Segment>

      {/* TODO: add bar here with businesses worked with or reviews from google or facebook */}

      {/* About this starter */}
      <Segment
        vertical
        className="stripe"
        style={{ height: determineHeightFromDevice('80vh', '40vh') }}
      >
        <Grid stackable verticalAlign="middle" className="container">
          <Grid.Row>
            <Grid.Column textAlign="center">
              <Header as="h1" style={{ fontFamily: 'Oswald' }}>
                Elite Concrete
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="8">
              <Header style={{ fontFamily: 'Oswald' }}>About Us</Header>
              <p style={{ fontFamily: 'Oswald' }}>
                Elite Concrete and Construction, established by the Clark family
                in the summer of 2010, has consistently upheld high standards
                within the concrete industry. This success is a direct result of
                our commitment to customer satisfaction and delivering superior
                quality work.
              </p>
              <br />
            </Grid.Column>
            <Grid.Column width="6" floated="right">
              {/* TODO replace with a pretty GIF */}
              <Header style={{ fontFamily: 'Oswald' }}>
                Schedule Appointment
              </Header>
              <p style={{ fontFamily: 'Oswald' }}>
                <Icon name="phone" />
                <a
                  style={{ fontFamily: 'Oswald', color: '#141a26' }}
                  href="tel:678-724-7984"
                >
                  678-724-7984
                </a>
              </p>
              <p style={{ fontFamily: 'Oswald' }}>
                <Icon name="envelope" />
                georgiaeliteconcrete@gmail.com
              </p>
              <p>
                <FormModalButton />
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      {/* Key features */}
      <Segment
        style={{ backgroundColor: '#E5E5E5' }}
        vertical
        className="stripe alternate feature"
      >
        <Grid
          columns="3"
          textAlign="center"
          divided
          relaxed
          stackable
          className="container"
        >
          <Grid.Row>
            <Grid.Column>
              <Bounce left>
                <Header
                  icon
                  style={{
                    fontFamily: 'Oswald',
                    color: '#415A77',
                    textAlign: '-webkit-center'
                  }}
                >
                  <img
                    src={concreteMixer}
                    style={{ height: '50px', width: '54px', display: 'block' }}
                  />
                  Services
                </Header>
              </Bounce>
              <p style={{ fontFamily: 'Oswald' }}>
                Patios, Driveways, Sidewalks, Foundations, and Removals
              </p>
            </Grid.Column>
            <Grid.Column>
              <Bounce left>
                <Header
                  icon
                  style={{
                    fontFamily: 'Oswald',
                    color: '#415A77',
                    textAlign: '-webkit-center'
                  }}
                >
                  <img
                    src={house}
                    style={{ height: '50px', width: '54px', display: 'block' }}
                  />
                  Residential & Commercial
                </Header>
              </Bounce>
              <p style={{ fontFamily: 'Oswald' }}>
                We have experience working on residential and commercial
                projects.
              </p>
            </Grid.Column>
            <Grid.Column>
              <Bounce left>
                <Header
                  icon
                  style={{
                    fontFamily: 'Oswald',
                    color: '#415A77',
                    textAlign: '-webkit-center'
                  }}
                >
                  <img
                    src={mobilePhone}
                    style={{ height: '50px', width: '54px', display: 'block' }}
                  />
                  Free Estimate
                </Header>
              </Bounce>
              <p style={{ fontFamily: 'Oswald' }}>
                Contact us here or give us a call for a quick and easy estimate
                with 24 hours!
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      {/* Images Carousel */}
      <Segment vertical textAlign={'center'} raised>
        <Header as="h1" style={{ textAlign: 'center', fontFamily: 'Oswald' }}>
          Our Recent Projects
        </Header>
        <Carousel />
        <ImageCarousel
          images={[
            job1Img,
            job2Img,
            job3Img,
            job4Img,
            job5Img,
            job6Img,
            job7Img,
            job8Img,
            job9Img,
            job10Img,
            job11Img,
            job12Img,
            job13Img,
            job14Img,
            job15Img,
            job16Img,
            job17Img,
            job18Img,
            job19Img,
            job20Img,
            job21Img,
            job22Img,
            job23Img,
            job24Img,
            job25Img,
            job26Img,
            job27Img,
            job28Img,
            job29Img,
            job30Img,
            job31Img,
            job32Img,
            job33Img,
            job34Img,
            job35Img,
            job36Img,
            job37Img,
            job38Img,
            job39Img,
            job40Img,
            job41Img,
            job42Img,
            job43Img,
            job44Img,
            job45Img,
            job46Img,
            job47Img,
            job48Img,
            job49Img,
            job50Img,
            job51Img,
            job52Img,
            job53Img,
            job54Img,
            job55Img,
            job56Img,
            job57Img,
            job58Img,
            job59Img,
            job60Img,
            job61Img,
            job62Img,
            job63Img,
            job64Img,
            job65Img,
            job66Img,
            job67Img,
            job68Img,
            job69Img,
            job70Img
          ]}
        />
        {/* <Instagram /> */}
      </Segment>

      {/* testimonials */}
      <Segment
        textAlign="center"
        raised
        vertical
        className="stripe alternate feature"
        style={{ backgroundColor: '#E5E5E5' }}
      >
        <Grid
          stackable
          textAlign={'center'}
          verticalAlign="middle"
          className="container"
        >
          <Grid.Row>
            <Grid.Column textAlign="center">
              <Header style={{ fontFamily: 'Oswald' }} as="h1">
                Testimonials
              </Header>
              <p style={{ fontFamily: 'Oswald' }}>
                We've helped home owners and businesses throughout the southeast
                bring their ideas to life!
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid stackable className="container" relaxed columns="3" centered>
          {/* Customer Review Cards */}
          <Grid.Row centered>
            <CustomerReviewCards />
          </Grid.Row>
        </Grid>
      </Segment>
    </div>
  )
}

export default withLayout(IndexPage)
