import * as React from 'react'
import './ImageCarousel.css'

interface Props {
  images: string
}

const ImageCarousel = (props: Props) => {
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0)
  const images = props.images

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      const newIndex = currentImageIndex + 1
      if (newIndex === images.length) {
        setCurrentImageIndex(0)
      } else {
        setCurrentImageIndex(newIndex)
      }
    }, 5000)
    return () => clearInterval(intervalId)
  }, [currentImageIndex, images.length])

  const previousImage = () => {
    const newIndex = currentImageIndex - 1
    if (newIndex < 0) {
      setCurrentImageIndex(images.length - 1)
    } else {
      setCurrentImageIndex(newIndex)
    }
  }

  const nextImage = () => {
    const newIndex = currentImageIndex + 1
    if (newIndex === images.length) {
      setCurrentImageIndex(0)
    } else {
      setCurrentImageIndex(newIndex)
    }
  }

  return (
    <div className="image-carousel">
      <img
        className="image-carousel__image"
        src={images[currentImageIndex]}
        alt="Image Carousel"
      />
      <div className="image-carousel__controls">
        <button
          className="image-carousel__button image-carousel__button--previous"
          onClick={previousImage}
        >
          Previous
        </button>
        <button
          className="image-carousel__button image-carousel__button--next"
          onClick={nextImage}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default ImageCarousel
