import * as React from 'react'
import ItemsCarousel from 'react-items-carousel'
import { Image } from 'semantic-ui-react'
import { isMobile } from 'react-device-detect'
import { Button, Icon } from 'semantic-ui-react'
import { Instagram } from '../Instagram/Instagram'

export const Carousel = () => {
  const [activeItemIndex, setActiveItemIndex] = React.useState(0)
  const chevronWidth = 40

  const carousel = () => {
    // images are currently 450px X 450 px
    const images = [
      {
        src:
          'https://i.ibb.co/wMs2qqY/73078634-104548090973190-6405074553747275776-n.jpg',
        alt: 'concrete'
      },
      {
        src:
          'https://i.ibb.co/N7dTGjM/74792856-104547234306609-5947836651857248256-n.jpg',
        alt: 'concrete'
      },
      {
        src:
          'https://i.ibb.co/1JdbN4c/75369300-104547314306601-4664954728273149952-n.jpg',
        alt: 'concrete'
      },
      {
        src: 'https://i.ibb.co/4pF8Rdh/concrete.jpg',
        alt: 'concrete'
      }
    ]

    return images.map(image => {
      return (
        <div style={{ height: 450, width: 450, background: '#ffff' }}>
          <Image src={image.src} alt={image.alt} />
        </div>
      )
    })
  }

  const imageDisplayCount = isMobile === true ? 1 : 3

  return (
    <div style={{ padding: `0 ${chevronWidth}px` }}>
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={imageDisplayCount}
        gutter={20}
        leftChevron={
          <Button icon>
            <Icon name="chevron left" />
          </Button>
        }
        rightChevron={
          <Button icon>
            <Icon name="chevron right" />
          </Button>
        }
        outsideChevron
        chevronWidth={chevronWidth}
      >
        <Instagram />
      </ItemsCarousel>
    </div>
  )
}
