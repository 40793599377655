import useMediaQuery from '@material-ui/core/useMediaQuery'

export const determineHeightFromDevice = (
  mobileHeight: string,
  webHeight: string
) => {
  const matches = useMediaQuery('(max-width:500px)')

  let height = ''
  if (matches) {
    height = mobileHeight
  } else {
    height = webHeight
  }

  return height
}
