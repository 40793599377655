import * as React from 'react'
import { determineHeightFromDevice } from '../../utils/helpers'
import InstagramEmbed from 'react-instagram-embed'

export const Instagram: React.FC = () => {
  const [igPostsUrls, updatePosts] = React.useState([])

  React.useEffect(() => {
    // setTimeout(fetchIgPosts(), 10000)
    // fetchIgPosts()
    // userInstagram('clarksconcreteconstruction')
    //   .then((data: any) => {
    //     console.log('data', data)
    //     console.log('data.posts', data.posts)
    //     const { posts } = data
    //     // data.setHeader('Set-Cookie', 'HttpOnly;Secure;SameSite=Strict')
    //     updatePosts(posts)
    //   })
    //   .catch((error: any) => {
    //     // error.setHeader('Set-Cookie', 'HttpOnly;Secure;SameSite=Strict')
    //     console.log('error', error)
    //   })
  }, [])

  React.useEffect(() => {
    console.log('igPostsUrls', igPostsUrls)
  }, [igPostsUrls])

  const fetchIgPosts = () => {
    let igPosts: any = []

    // try {
    //   const data = await userInstagram('masterpeter')
    //   console.log('posts', data.posts)
    //   data.posts.forEach((post: any, i: number) => {
    //     if (i < 6) {
    //       igPosts.push(post.url)
    //     }
    //   })
    //   updatePosts(igPosts)
    //   console.log('igPosts', igPosts)
    // } catch (e) {
    //   console.log('shit something broke: ', e)
    // }
    userInstagram('clarksconcreteconstruction')
      .then(console.log)
      .catch(console.error)
  }

  const instagramPostUrls = [
    'https://www.instagram.com/p/B_3BuRsn8s5/',
    'https://www.instagram.com/p/B_0FebNH_Ol/',
    'https://www.instagram.com/p/B97HMcpHnBc/',
    'https://www.instagram.com/p/B94ZdijH21L/',
    'https://www.instagram.com/p/B-dF4leHl1F/',
    'https://www.instagram.com/p/B9w3rDxnlyT/'
  ]

  const feed = igPostsUrls.map((post, index) => {
    if (index < 5) {
      return (
        <InstagramEmbed
          url={post.url}
          maxWidth={320}
          hideCaption={true}
          containerTagName="div"
          protocol=""
          injectScript
          onLoading={() => {}}
          onSuccess={() => {}}
          onAfterRender={() => {}}
          onFailure={() => {}}
          key={post.id}
          style={{
            marginRight: '30px',
            textAlign: '-webkit-center',
            display: 'inline'
          }}
        />
      )
    }
  })

  return (
    <div style={{ display: determineHeightFromDevice('block', 'inline-flex') }}>
      {feed}
    </div>
  )
}
