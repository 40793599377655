import * as React from 'react'
import { Grid, Icon, Card } from 'semantic-ui-react'
import Bounce from 'react-reveal/Bounce'

export const CustomerReviewCards: React.FC = (props: any) => {
  const cards = [
    {
      review: `"Mr. Clark was able to work around our schedule doing the
        work at night so we were able to keep the store open and
        running."`,
      name: `America's Best Eye Glass`
    },
    {
      review: `"Your crew and professionalism was great and poured my patio
        with ease and no problems. Thanks Elite Concrete."`,
      name: `Dawn Hall`
    },
    {
      review: `"Thanks for replacing my driveway in a timely matter and
        doing such a nice job."`,
      name: `Ms. Andrews`
    }
  ]

  const reviews = cards.map(card => {
    return (
      <Grid.Column style={{ textAlign: '-webkit-center' }}>
        <Bounce left>
          <Card style={{ height: '210px' }}>
            <Card.Content style={{ fontFamily: 'Oswald' }} textAlign={'center'}>
              <p style={{ fontFamily: 'Oswald' }}>{card.review}</p>
            </Card.Content>
            <Card.Content
              style={{ fontFamily: 'Oswald' }}
              extra={true}
              textAlign={'center'}
            >
              <Icon name="user outline" color="orange" circular />
              {card.name}
            </Card.Content>
          </Card>
        </Bounce>
      </Grid.Column>
    )
  })

  return <React.Fragment>{reviews}</React.Fragment>
}
